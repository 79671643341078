2<template>
    <div class="db-container dashboard-pro-portfolios-detail">
        <div v-if="!this.loading" class="db-content">
            <h2 class="title">
                Portfolio Details
            </h2>
            <a class="backLink" href="/dashboard-pro/portfolios">Back</a>
            <div :class="`info ${notInvestor ? '' : 'no-tab'}`">
                <div class="title">
                    <img :src="loadIcon(this.info.standard.toUpperCase())" alt="" class="icon"><h4>{{ this.info.product_name }} <span v-if="this.info.status == 1" class="green">In Operation</span><span v-if="this.info.status == 0" class="red">Settled</span></h4>
                </div>
                <div class="subtitle">
                    <p>Initialized on {{ new Date(Number(this.info.establish_since) * 1000) | dateFormat }}</p>
                    <p>Standard:<span>{{ this.info.standard.toUpperCase() }}</span></p>
                </div>
                <p v-if="forDemo" class="description">
                    {{ this.info.note }}
                </p>
                <div class="statics">
                    <div class="static">
                        <img src="@/assets/images/dashboard-pro/pnl-since-inception.svg" />
                        <h3>{{ this.info.equity | currency(2, true) }} {{ this.info.standard.toUpperCase() }}</h3>
                        <p>Total Equity</p>
                    </div>
                    <div class="static">
                        <img src="@/assets/images/dashboard-pro/total-equitiy-value.svg" />
                        <h3>{{ Number(this.info.nav) - 1 | rate(2) }}%</h3>
                        <p>Return Since Inception</p>
                    </div>
                    <div class="static">
                        <img src="@/assets/images/dashboard-pro/max-dd.svg" />
                        <h3>{{ (pfmce.max_dd * 100).toFixed(2) }}%</h3>
                        <p>Maximum drawdown</p>
                    </div>
                </div>
                <div v-if="notInvestor" class="tab-container">
                    <div class="tabs">
                        <div :key="ind" v-for="(ele, ind) in tabOptions" :class="`tab${ele.value == selectedTab ? ' active' : ''}`" @click="selectedTab=ele.value">{{ ele.title }}</div>
                    </div>
                </div>
            </div>
            <div v-if="selectedTab == 'overview'" class="controller">
                <Switcher :options="periodOptions" v-model="period" />
            </div>
            <div v-if="selectedTab == 'overview' && !this.loadingRecords" class="content">
                <div class="large chart">
                    <h6>Net Value</h6>
                    <h3>{{ navData.length != 0 ? navData.slice(-1)[0].data : 0 }}<h5 v-if="false" class="increase">+10.23%</h5></h3>
                    <p>Net Value</p>
                    <div>
                        <DashboardEquitiyValue :records="navData" :isCurrency="false" :height="200" :styles="myStyles"/>
                    </div>
                </div>
                <div class="small small-chart">
                    <!-- <h6>APY Stats</h6>
                    <h3>{{ apyData.length != 0 ? apyData.slice(-1)[0].data : 0 | rate(2) }}%</h3>
                    <p>APY Since Inception</p>
                    <div class="chart-container" style="margin: 0px 0px 0px -8px;">
                        <APYStats :records="apyData" :styles="myStyles"/>
                    </div> -->
                    <h6>Stats</h6>
                    <div class="container">
                        <div class="item">
                            <div class="name">Net Value</div>
                            <div :class="['value', pfmce.nav >= 1 ? 'positive' : 'negative']">{{ pfmce.nav.toFixed(4) }}</div>
                        </div>
                        <div class="item">
                            <div class="name">APY</div>
                            <div :class="['value', pfmce.apy >= 0 ? 'positive' : 'negative']">{{ pfmce.apy >= 0 ? '+' : '' }}{{ Number(pfmce.apy) | rate(2) }}%</div>
                        </div>
                        <div class="item">
                            <div class="name">Max Drawdown</div>
                            <div class="value">{{ (pfmce.max_dd * 100).toFixed(2) }}%</div>
                        </div>
                        <div class="item">
                            <div class="name">Calmar Ratio</div>
                            <div class="value">{{ pfmce.calmar.toFixed(2) }}</div>
                        </div>
                        <div class="item">
                            <div class="name">Sharpe Ratio</div>
                            <div class="value">{{ pfmce.sharpe.toFixed(4) }}</div>
                        </div>
                        <div class="item">
                            <div class="name">Update Date</div>
                            <div class="value">{{ new Date(Number(pfmce.update_ts) * 1000) | dateFormat }}</div>
                        </div>
                    </div>
                </div>
                <div v-if="this.info.inv_type==='structured'" class="large chart">
                    <h6>Tranche Net Value</h6>
                    <h3>{{ absNavData.length != 0 ? absNavData.slice(-1)[0].data : 0 }}<h5 v-if="false" class="increase">+10.23%</h5></h3>
                    <p>Inferior Net Value</p>
                    <div>
                        <DashboardEquitiyValue :records="absNavData" :height="200" :styles="myStyles"/>
                    </div>
                </div>
                <div v-if="this.info.inv_type==='structured'" class="small small-chart">
                    <!-- <h6>APY Stats</h6>
                    <h3>{{ apyData.length != 0 ? apyData.slice(-1)[0].data : 0 | rate(2) }}%</h3>
                    <p>APY Since Inception</p>
                    <div class="chart-container" style="margin: 0px 0px 0px -8px;">
                        <APYStats :records="apyData" :styles="myStyles"/>
                    </div> -->
                    <h6>Tranch Stat</h6>
                    <div class="container">
                        <div class="item">
                            <div class="name">Net Value</div>
                            <div :class="['value', pfmce_tranche.nav >= 1 ? 'positive' : 'negative']">{{ pfmce_tranche.nav.toFixed(4) }}</div>
                        </div>
                        <div class="item">
                            <div class="name">APY</div>
                            <div :class="['value', pfmce_tranche.apy >= 0 ? 'positive' : 'negative']">{{ pfmce_tranche.apy >= 0 ? '+' : '' }}{{ Number(pfmce_tranche.apy) | rate(2) }}%</div>
                        </div>
                        <div class="item">
                            <div class="name">Max Drawdown</div>
                            <div class="value">{{ (pfmce_tranche.max_dd * 100).toFixed(2) }}%</div>
                        </div>
                        <div class="item">
                            <div class="name">Calmar Ratio</div>
                            <div class="value">{{ pfmce_tranche.calmar.toFixed(2) }}</div>
                        </div>
                        <div class="item">
                            <div class="name">Sharpe Ratio</div>
                            <div class="value">{{ pfmce_tranche.sharpe.toFixed(4) }}</div>
                        </div>
                        <div class="item">
                            <div class="name">Update Date</div>
                            <div class="value">{{ new Date(Number(pfmce_tranche.update_ts) * 1000) | dateFormat }}</div>
                        </div>
                    </div>
                </div>
                <div class="large chart">
                    <h6>Total Equity</h6>
                    <h3><img :src="loadIcon(this.info.standard.toUpperCase())" alt="" class="icon">{{ equityData.length != 0 ? equityData.slice(-1)[0].data : 0 | currency(2, true) }}<h5 v-if="false" class="increase">+10.23%</h5></h3>
                    <p>Total Equity</p>
                    <div>
                        <DashboardEquitiyValue :records="equityData" :height="200" :styles="myStyles"/>
                    </div>
                </div>
                <div v-if="this.info.inv_type==='structured'" class="large chart">
                    <h6>Tranche Equity</h6>
                    <h3><img :src="loadIcon(this.info.standard.toUpperCase())" alt="" class="icon">{{ absEquityData.length != 0 ? absEquityData.slice(-1)[0].data : 0 | currency(2, true) }}<h5 v-if="false" class="increase">+10.23%</h5></h3>
                    <p>Inferior Equity</p>
                    <div>
                        <DashboardEquitiyValue :records="absEquityData" :height="200" :styles="myStyles"/>
                    </div>
                </div>
                <div v-if="!forDemo && !(volumeData.reduce((acc, cur) => acc && (cur.spot == 0 && cur.contract == 0), true))" class="small vertical-chart">
                    <h6>Trading Volume Stats</h6>
                    <h3><img :src="loadIcon(this.info.standard.toUpperCase())" alt="" class="icon">{{ volumeData.length != 0 ? volumeData.reduce((acc, cur) => acc + cur.total, 0) : 0 | currency(2, true) }}</h3>
                    <p>Total Trading Volume</p>
                    <div class="chart">
                        <div :key="ind" v-for="(ele, ind) in volumeData">
                            <div class="first" :style="`height: ${ele.spot * 100}%;`"></div>
                            <div class="second" :style="`height: ${ele.contract * 100}%;`"></div>
                        </div>
                    </div>
                    <div class="description">
                        <p>Spot</p>
                        <p>Contract</p>
                    </div>
                </div>
                <div v-if="!forDemo && hasPosition" class="large portfolios-table">
                    <h6>Contract Positions<a v-if="false" href="#">View All</a></h6>
                    <div class="table-container">
                        <table>
                            <tr>
                                <th>Direction</th>
                                <th>Value</th>
                                <th>Leverage</th>
                            </tr>
                            <tr>
                                <td><img src="@/assets/images/dashboard-pro/increase.svg" />Long</td>
                                <td>{{ (this.position.length > 0 ? this.position[0].long_value : 0) | currency(2, true) }} {{ this.info.standard.toUpperCase() }}</td>
                                <td>{{ this.position.length > 0 ? Number(this.position[0].long_lvg).toFixed(2) : 0 }}</td>
                            </tr>
                            <tr>
                                <td><img src="@/assets/images/dashboard-pro/decrease.svg" />Short</td>
                                <td>{{ (this.position.length > 0 ? this.position[0].short_value : 0) | currency(2, true) }} {{ this.info.standard.toUpperCase() }}</td>
                                <td>{{ this.position.length > 0 ? Number(this.position[0].short_lvg).toFixed(2) : 0 }}</td>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td>{{ (this.position.length > 0 ? this.position[0].total_value : 0) | currency(2, true) }} {{ this.info.standard.toUpperCase() }}</td>
                                <td>{{ this.position.length > 0 ? Number(this.position[0].total_lvg).toFixed(2) : 0 }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div v-if="selectedTab == 'exchangeAccounts'">
                <ExchangeAccountsTable :data="this.info.accounts" :defaultPortfolio="this.$route.params.id" />
            </div>
            <div v-if="selectedTab == 'users'">
                <UsersTable v-if="!loadingUsers" type="in-portfolio" :portfolio="this.info.product_name" :data="this.users" />
            </div>
            <div v-if="selectedTab == 'settings' && !this.loading" class="setting-panel">
                <div class="controll">
                    <div :class="settingType == 0 ? 'active' : ''" @click="settingType = 0">Basic Info</div>
                    <div :class="settingType == 1 ? 'active' : ''" @click="settingType = 1">Advanced Settings</div>
                    <div :class="settingType == 2 ? 'active' : ''" @click="settingType = 2">Upload Records</div>
                </div>
                <div v-if="settingType == 0" class="panel">
                    <h3>Basic Info</h3>
                    <div class="form">
                        <InputUnit title="Portfolio Name" type="text" :value="config.product_name" :disabled="true"/>
                        <InputUnit title="Standard" type="text" :value="info.standard" :disabled="true"/>
                        <InputUnit class="full" title="Description" type="textarea" v-model="config.note" />
                        <InputUnit title="Status" type="select" :selectOptions="statusOptions" v-model="config.status" :minVal="0" :hasBtn="false" />
                    </div>
                    <div v-if="hasAdmin" class="btn-container">
                        <button class="blue" @click="editPortfolio()">Save</button>
                    </div>
                </div>
                <div v-if="settingType == 1" class="panel">
                    <h3>Advanced Settings</h3>
                    <div class="form">
                        <InputUnit class="mobile-full" :title="`Initial Fund(${this.info.standard.toUpperCase()})`" type="number" v-model="config.init_fund" placeholder="Amount" :hasBtn="false" :minVal="0.0"  :disabled="false"/>
                        <InputUnit class="mobile-full" title="Financing" type="number" v-model="config.financing" placeholder="Amount" :hasBtn="false" :minVal="0.0"  :disabled="false"/>
                        <InputUnit class="mobile-full" title="Financing Rate(‱)" type="number" v-model="config.financing_rate" placeholder="Rate" :hasBtn="false" :minVal="0.0"  :disabled="false"/>
                        <InputUnit class="mobile-full" title="Interest Since" type="date" v-model="config.interest_since" placeholder="Interest start time" :hasBtn="false" />
                        <div class="switches mobile-full">
                            <Switches color="blue" v-model="config.active"></Switches>Enabled recording net value
                        </div>
                        <InputUnit class="full" v-model="config.cache" title="Cache" type="textarea" />
                    </div>
                    <div v-if="hasAdmin" class="btn-container">
                        <button class="blue" @click="editPortfolio()">Save</button>
                    </div>
                    <div v-if="hasAdmin">
                        <h3 class="delete-headline">Delete Portfolio</h3>
                        <p class="delete-notice">Delete action will remove all portfolio data, please operate cauciously.</p>
                        <div class="btn-container">
                            <button class="light" @click="deletePortfolio()">Delete</button>
                        </div>
                    </div>
                </div>
                <div v-if="settingType == 2" class="panel">
                    <h3>Upload Records</h3>
                    <div class="form">
                        <input id="recordUpload" type="file" @change="onFileChange" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" hidden>
                        <div :class="`upload-area ${this.recordToUpload === null ? '' : 'selected'}`" @click="recordToUpload === null ? chooseFiles()  : () => {}">
                            <p v-if="this.recordToUpload === null">Select File (.XLSX)</p>
                            <p v-if="this.recordToUpload !== null">{{ this.recordToUpload.name.split(/(\\|\/)/g).pop() }}</p>
                            <img v-if="this.recordToUpload !== null" src="../../assets/images/close-thin.svg" class="delete" @click.stop="recordToUpload = null" />
                        </div>
                    </div>
                    <div class="btn-container">
                        <a class="example" href="/files/RecordExample.xlsx" download>Download Example</a>
                        <button class="blue" @click="handleUploadRecords()">Upload</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
// import $ from 'jquery';
//this.$route.params.id
import Switcher from '@/components/Switcher';
import InputUnit from '@/components/InputUnit';
import DashboardEquitiyValue from '@/components/DashboardEquitiyValue';
// import APYStats from '@/components/APYStats';
import ExchangeAccountsTable from '@/components/ExchangeAccountsTable';
import UsersTable from '@/components/UsersTable';
import Switches from 'vue-switches';
import moment from 'moment'
import {apiPortfolioInfo, apiPortfolioRecord, apiPortfolioPosition, apiAddPortfolio, apiDeletePortfolio, apiAddPortfolioRecords, apiPortfolioAbsRecord} from '@/apis/pro/portfolios.js'
import {apiGetUserShare} from '@/apis/pro/user.js'
import Cookies from 'js-cookie';
//import * as xlsx from 'xlsx';

export default {
    name: 'PortfoliosDetail',
    beforeCreate: function() {
        document.body.className = 'noscroll';
    },
    data() {
        return {
            periodOptions: [{
                title: '7 Days',
                value: '7'
            }, {
                title: '30 Days',
                value: '30'
            }, {
                title: '90 Days',
                value: '90'
            }, {
                title: 'All',
                value: 'all'
            }],
            period: '7',
            searchPortfolio: '',
            selectedTab: 'overview',
            exchangeAccounts: [{
                id: '132',
                name: 'USDT01_onebitARB',
                exchange: 'Binance',
                portfolios: 'Portfolio A',
                standard: 'BTC'
            }],
            portfolio: {
                status: 'operating',
                recording: true
            },
            tabOptions: [{
                title: 'Overview',
                value: 'overview',
            },{
                title: 'Exchange Accounts',
                value: 'exchangeAccounts',
            },{
                title: 'Users',
                value: 'users',
            },{
                title: 'Settings',
                value: 'settings',
            }],
            statusOptions: [{
                title: "Operating",
                value: '1',
            }, {
                title: "Settled",
                value: '0',
            }],
            settingType: 0, //0:basuc 1:advance
            info: {},
            loading: true,
            loadingRecords: true,
            records: [],
            absRecord: [],
            loadingPosition: true,
            position: [],
            config: {
                product_name: '',
                note: '',
                status: 1,
                init_fund: 0.0,
                financing: 0,
                active: 1,
                financing_rate: 0.0,
                interest_since: 0,
                cache: '',
            },
            configBackup: {},
            users: [],
            loadingUsers: true,
            recordToUpload: null
        }
    },
    components: {
        Switcher,
        DashboardEquitiyValue,
        // APYStats,
        ExchangeAccountsTable,
        UsersTable,
        InputUnit,
        Switches
    },
    methods: {
        loadIcon(name = ""){
            if(name == "GBP")return `https://static.bitfinex.com/images/icons/GBP-alt.svg`;
            try {
                return require(`@/assets/images/crypto-logos/${name.replace('-PERP', '')}.png`);
            } catch (e) {
                // return require(`@/assets/images/crypto-default.png`);
                return `https://static.bitfinex.com/images/icons/${name.replace('-PERP', '')}-alt.svg`;
            }
        },
        loadInfo(){
            apiPortfolioInfo({
                name: this.$route.params.id
            }).then(res => {
                if(res.data.result){
                    this.info = res.data.data
                    const {product_name, note, status, init_fund, financing, active, financing_rate, interest_since, cache,} = res.data.data
                    this.config = {product_name, note, status, init_fund, financing, active, financing_rate, interest_since: interest_since == 0 ? 0 : moment(new Date(interest_since * 1000)).format('YYYY-MM-DD'), cache,}
                    this.configBackup = Object.assign({}, this.config)
                    this.loading = false
                }else{
                    this.$toast.error(res.data.err_msg, {
                        timeout: 2000
                    });
                }
            })
        },
        loadRecord(){
            apiPortfolioRecord({
                portfolio: this.$route.params.id,
                start: 0,
                hour: 8,
            }).then(res => {
                if(res.data.result){
                    this.records = res.data.data
                    this.loadingRecords = false
                }else{
                    this.$toast.error(res.data.err_msg, {
                        timeout: 2000
                    });
                }
            })
        },
        loadAbsRecord(){
            apiPortfolioAbsRecord({
                portfolio: this.$route.params.id,
                start: 0,
                hour: 8,
            }).then(res => {
                if(res.data.result){
                    this.absRecord = res.data.data
                    this.loadingRecords = false
                }else{
                    this.$toast.error(res.data.err_msg, {
                        timeout: 2000
                    });
                }
            })
        },
        calPerformance(data) {
            if (data.length > 1) {
                const last_day = data.slice(-1)[0]
                const first_day = data.slice(0)[0]
                const nav = last_day.nav
                const change = (nav - first_day.nav) / first_day.nav
                const n_day = Math.floor((last_day.ts - first_day.ts + 60) / 86400)
                const apy = change / n_day * 365
                const roids = data.slice(-1 * n_day).map(d => d.roi_d)
                // roids.splice(0, 1, 0)
                const sum = roids.reduce((total, num) => total + num, 0)
                const mean = sum / n_day
                const squaredDifferencesSum = roids.reduce(
                    (sum, value) => sum + Math.pow(value - mean, 2),
                    0
                )
                const sigma = Math.sqrt(squaredDifferencesSum / n_day)
                var peak = 1
                var valley = 1
                var maxDD = 0
                data.forEach(d => {
                    if (d.nav > peak) {
                        peak = d.nav
                        valley = d.nav
                    } else if (d.nav < peak) {
                        if (valley > d.nav) {
                        valley = d.nav
                        }
                    }
                    maxDD = Math.max((peak - valley) / peak, maxDD)
                })
                return {
                    change: change,
                    apy: apy,
                    nav: nav,
                    max_dd: maxDD,
                    calmar: maxDD !== 0 ? change / maxDD : 0,
                    sharpe: sigma !== 0 ? mean / sigma * Math.sqrt(365) : 0,
                    update_ts: last_day.ts
                }
            } else {
                return { change: 0, apy: 0, max_dd: 0, calmar: 0, sharpe: 0, update_ts: this.info.ts, nav: this.info.nav }
            }
        },
        loadPosition(){
            apiPortfolioPosition({
                product: this.$route.params.id,
            }).then(res => {
                if(res.data.result){
                    this.position = res.data.data
                    this.loadingPosition = false
                }else{
                    this.$toast.error(res.data.err_msg, {
                        timeout: 2000
                    });
                }
            })
        },
        loadUsers(){
            apiGetUserShare({
                portfolio: this.$route.params.id,
            }).then(res => {
                if(res.data.result){
                    this.users = res.data.data.map(item => Object.assign({role: ''}, item))
                    this.loadingUsers = false
                }else{
                    this.$toast.error(res.data.err_msg, {
                        timeout: 2000
                    });
                }
            })
        },
        validation(){
            
        },
        editPortfolio(){
            let tmp = Object.assign({}, this.config);
            tmp.active = tmp.active ? 1 : 0
            if(tmp.interest_since == 0){
                delete tmp.interest_since
            }else{
                tmp.interest_since = new Date(this.config.interest_since).getTime() / 1000
            }
            apiAddPortfolio(tmp).then(res => {
                if(res.data.result){
                    this.$toast.success('Portfolio Updated!', {
                        timeout: 2000
                    });
                    location.reload()
                }else{
                    this.$toast.error(res.data.err_msg, {
                        timeout: 2000
                    });
                }
            })
        },
        deletePortfolio(){
            this.$confirm('Delete Portfolio', `Are you sure you want to delete this portfolio?`).then(res => {
                if(res){
                    apiDeletePortfolio({
                        product_name: this.$route.params.id,
                    }).then(res => {
                        if(res.data.result){
                            this.$toast.success('Portfolio Deleted!', {
                                timeout: 2000
                            });
                            window.location = '/dashboard-pro/portfolios';
                        }else{
                            this.$toast.error(res.data.err_msg, {
                                timeout: 2000
                            });
                        }
                    })
                }
            })
        },
        chooseFiles() {
            document.getElementById("recordUpload").click()
        },
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files
            if (!files.length)return
            this.recordToUpload = files[0]
        },
        handleUploadRecords(){
            /*
            let self = this
            if(this.recordToUpload == null)return
            let reader = new FileReader()
            reader.readAsBinaryString(this.recordToUpload)
            reader.onload = function (evt) {
                let records = ''
                try {
                    let data = evt.target.result
                    var workbook = xlsx.read(data, { type: 'binary' })
                    let recordsRawData = workbook.Sheets[workbook.SheetNames[0]]
                    let range = Number(recordsRawData['!ref'].split(':')[1].slice(1))
                    for(var i = 2; i <= range; i++){
                        records += `${recordsRawData['A' + i].w},${recordsRawData['B' + i].w};`
                    }
                } catch (error) {
                    self.$toast.error('Incorrect File Format!', {
                        timeout: 2000
                    })
                    return
                }
                apiAddPortfolioRecords({
                    portfolio: self.$route.params.id,
                    hist: records
                }).then(res => {
                    if(res.data.result){
                        self.$toast.success('Portfolio Updated!', {
                            timeout: 2000
                        });
                        location.reload()
                    }else{
                        self.$toast.error(res.data.err_msg, {
                            timeout: 2000
                        });
                    }
                })
            }*/
            if(this.recordToUpload == null){
                this.$toast.error('Please upload your file!', {
                    timeout: 2000
                });
                return
            }
            const formData = new FormData()
            formData.append("portfolio", this.$route.params.id)
            formData.append("file", this.recordToUpload)
            apiAddPortfolioRecords(formData).then(res => {
                if(res.data.result){
                    this.$toast.success('Portfolio Updated!', {
                        timeout: 2000
                    });
                    location.reload()
                }else{
                    this.$toast.error(res.data.err_msg, {
                        timeout: 2000
                    });
                }
            })
        }
    },
    mounted() {
        this.loadInfo()
        this.loadRecord()
        this.loadAbsRecord()
        this.loadPosition()
        this.loadUsers()
    },
    computed: {
        myStyles() {
          return {
            height: '100%',
            width: '100%',
            position: 'relative',
          };
        },
        navData() {
            if(this.records.length == 0) return []
            // return this.records.map(item => ({label: new Date(Number(item.ts)*1000), data: item.nav}))
            const start = this.period==='all'? 0 : new Date().getTime() - (parseInt(this.period) +1) * 86400*1e3
            return this.records.filter(r => r.ts * 1e3 >= start).map(item => ({label: new Date(Number(item.ts)*1000), data: item.nav}))

            // if(this.period == 'all')return this.records.map(item => ({label: new Date(Number(item.ts)*1000), data: item.nav}))
            // let sl = parseInt(parseInt(this.period) + 1)
            // return this.records.slice(sl * -1).map(item => ({label: new Date(Number(item.ts)*1000), data: item.nav}))
            //return this.records.filter(item => (new Date().getTime() - item.ts * 1000) <= sl * 24 * 60 * 60 * 1000).map(item => ({label: new Date(Number(item.ts)*1000), data: item.nav}))
        },
        equityData() {
            if(this.records.length == 0)return []
            // return this.records.map(item => ({label: new Date(Number(item.ts)*1000), data: item.equity}))
            const start = this.period==='all'? 0 : new Date().getTime() - (parseInt(this.period) +1) * 86400*1e3
            return this.records.filter(r => r.ts * 1e3 >= start).map(item => ({label: new Date(Number(item.ts)*1000), data: item.equity}))
            // if(this.period == 'all')return this.records.map(item => ({label: new Date(Number(item.ts)*1000), data: item.equity}))
            // let sl = parseInt(parseInt(this.period) + 1)
            // return this.records.slice(sl * -1).map(item => ({label: new Date(Number(item.ts)*1000), data: item.equity}))
            //return this.records.filter(item => (new Date().getTime() - item.ts * 1000) <= sl * 24 * 60 * 60 * 1000).map(item => ({label: new Date(Number(item.ts)*1000), data: item.equity}))
        },
        absNavData() {
            if(this.absRecord.length == 0)return []
            // return this.records.map(item => ({label: new Date(Number(item.ts)*1000), data: item.equity}))
            const start = this.period==='all'? 0 : new Date().getTime() - (parseInt(this.period) +1) * 86400*1e3
            return this.absRecord.filter(r => r.ts * 1e3 >= start).map(item => ({label: new Date(Number(item.ts)*1000), data: item.nav}))
            // if(this.period == 'all')return this.records.map(item => ({label: new Date(Number(item.ts)*1000), data: item.equity}))
            // let sl = parseInt(parseInt(this.period) + 1)
            // return this.records.slice(sl * -1).map(item => ({label: new Date(Number(item.ts)*1000), data: item.equity}))
            //return this.records.filter(item => (new Date().getTime() - item.ts * 1000) <= sl * 24 * 60 * 60 * 1000).map(item => ({label: new Date(Number(item.ts)*1000), data: item.equity}))
        },
        absEquityData() {
            if(this.absRecord.length == 0)return []
            // return this.records.map(item => ({label: new Date(Number(item.ts)*1000), data: item.equity}))
            const start = this.period==='all'? 0 : new Date().getTime() - (parseInt(this.period) +1) * 86400*1e3
            return this.absRecord.filter(r => r.ts * 1e3 >= start).map(item => ({label: new Date(Number(item.ts)*1000), data: item.equity}))
            // if(this.period == 'all')return this.records.map(item => ({label: new Date(Number(item.ts)*1000), data: item.equity}))
            // let sl = parseInt(parseInt(this.period) + 1)
            // return this.records.slice(sl * -1).map(item => ({label: new Date(Number(item.ts)*1000), data: item.equity}))
            //return this.records.filter(item => (new Date().getTime() - item.ts * 1000) <= sl * 24 * 60 * 60 * 1000).map(item => ({label: new Date(Number(item.ts)*1000), data: item.equity}))
        },
        pfmce() {
            const start = this.period==='all'? 0 : new Date().getTime() - (parseInt(this.period) +1) * 86400*1e3
            return this.calPerformance(this.records.filter(r => r.ts * 1e3 >= start))
            // console.log(this.records.length,  this.period)
            // if(this.records.length < 1) return { change: 0, apy: 0, max_dd: 0, calmar: 0, sharpe: 0, update_ts: 0 }
            // if(this.period == 'all') return this.calPerformance(this.records)
            // let sl = parseInt(parseInt(this.period) + 1)
            // return this.calPerformance(this.records.slice(sl * -1))
            //return this.records.filter(item => (new Date().getTime() - item.ts * 1000) <= sl * 24 * 60 * 60 * 1000).map(item => ({label: new Date(Number(item.ts)*1000), data: item.roi_y}))
        },
        pfmce_tranche() {
            const start = this.period==='all'? 0 : new Date().getTime() - (parseInt(this.period) +1) * 86400*1e3
            return this.calPerformance(this.absRecord.filter(r => r.ts * 1e3 >= start))
        },
        volumeData() {
            // let sl = this.period == 'all' ? 10000 : parseInt(parseInt(this.period) + 1)
            // let data = this.records.slice(sl * -1).filter(item => item.trading_volume).map(item => Object.assign({}, item.trading_volume))
            const start = this.period==='all'? 0 : new Date().getTime() - (parseInt(this.period) +1) * 86400*1e3
            let data = this.records.filter(r => r.ts * 1e3 >= start).filter(item => item.trading_volume).map(item => Object.assign({}, item.trading_volume))

            //let data = this.records.filter(item => item.trading_volume && (new Date().getTime() - item.ts * 1000) <= sl * 24 * 60 * 60 * 1000).map(item => Object.assign({}, item.trading_volume))
            if(data.length == 0) return [];
            else if(data.length <= 7){
                data = data.map(item => ({
                    contract: item.contract ? item.contract : 0,
                    spot: item.spot ? item.spot : 0
                }))
                let max = data.reduce((pre, now) => pre > (now.contract + now.spot) ? pre : (now.contract + now.spot), 0)
                if(max == 0)max = 1
                data = data.map(item => ({
                    contract: item.contract / max,
                    spot: item.spot / max,
                    total: item.contract + item.spot
                }))
                return data
            }
            let len = data.length
            let ticks = Math.floor(len / 7)
            data = data.reduce((acc, cur, ind) => {
                if(ind % ticks ==0 && (ind + ticks) <= data.length){
                    acc.push({
                        contract: cur.contract ? cur.contract : 0,
                        spot: cur.spot ? cur.spot : 0
                    })
                }else{
                    acc[acc.length - 1].contract += cur.contract ? cur.contract : 0
                    acc[acc.length - 1].spot += cur.spot ? cur.spot : 0
                }
                return acc
            }, [])
            let max = data.reduce((pre, now) => pre > (now.contract + now.spot) ? pre : (now.contract + now.spot), 0)
            if(max == 0)max = 1
            data = data.map(item => ({
                contract: item.contract / max,
                spot: item.spot / max,
                total: item.contract + item.spot
            }))
            return data
        },
        notInvestor(){
            return Cookies.get('dcbotLogin_role') !== 'investor'
        },
        hasAdmin(){
            return Cookies.get('dcbotLogin_role') == 'admin'
        },
        forDemo() {
            return this.info.active == 0 && this.info.status == 1
        },
        hasPosition() {
            if(this.position.length == 0)return false
            if(Number(this.position[0].long_value) == 0 && Number(this.position[0].short_value) == 0 && Number(this.position[0].total_value) == 0)return false
            return true
        }
    },
    watch: {
        settingType(before, after){
            if(before != after){
                this.config = Object.assign({}, this.configBackup)
            }
        }
    }
}
</script>
<style scoped>
.positive {
  color: green;
}

.negative {
  color: red;
}
.container {
  display: flex;
  flex-wrap: wrap;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.name {
  font-size: 14px;
}

.value {
  margin-top: 5px;
  font-weight: bold;
}
</style>