<script>
import { Line } from 'vue-chartjs'
import moment from 'moment'

export default {
    extends: Line,
    data() {
        return{
            options: {
                maintainAspectRatio: false,
                scaleShowVerticalLines: false,
                responsive: true,
                legend: {
                    display: false,
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            maxTicksLimit: 4,
                            userCallback: function(num) {
                                if(Math.abs(num) >= 1000000000){
                                    return `${Math.round(num / 1000000000 * 100) / 100}B`;
                                }else if(Math.abs(num) >= 1000000){
                                    return `${Math.round(num / 1000000 * 100) / 100}M`;
                                }else if(Math.abs(num) >= 1000){
                                    return `${Math.round(num / 1000 * 100) / 100}K`;
                                }
                                return num.toFixed(3);
                            },
                            padding: 20,
                            beginAtZero: false
                        },
                        gridLines: {
                            drawOnChartArea: true,
                            drawBorder: false,
                            color: '#F4F3F6',
                            zeroLineColor: '#F4F3F6'
                        },
                    }],
                    xAxes: [{
                        ticks: {
                            maxTicksLimit: 7,
                            padding: 20,
                            maxRotation: 45,
                        },
                        gridLines: {
                            drawOnChartArea: false,
                            display: false,
                            color: '#F4F3F6',
                            zeroLineColor: '#F4F3F6'
                        },
                    }]
                },
                tooltips: {
                    enabled: false,
                    custom: function(tooltipModel) {
                        // Tooltip Element
                        var tooltipEl = document.getElementById('chartjs-tooltip');

                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip';
                            tooltipEl.innerHTML = '<table></table>';
                            document.body.appendChild(tooltipEl);
                        }

                        // Hide if no tooltip
                        
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = 0;
                            return;
                        }

                        // Set caret Position
                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                        if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                        } else {
                            tooltipEl.classList.add('no-transform');
                        }

                        function getBody(bodyItem) {
                            return bodyItem.lines;
                        }

                        // Set Text
                        if (tooltipModel.body) {
                            var bodyLines = tooltipModel.body.map(getBody);
                            var innerHtml = '<tbody>';
                            bodyLines.forEach(function(body) {
                                let notCurrency = body.toString().split(':')[0] == 'notCurrency'
                                if(notCurrency){
                                    innerHtml += '<tr><td style="color: white;">' + Number(body.toString().split(':')[1]) + '</td></tr>';
                                }else{
                                    innerHtml += '<tr><td style="color: white;">' + Number(body).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,').slice(0, -2) + '</td></tr>';
                                }
                                innerHtml += `<tr><td><img src="${require('@/assets/images/dashboard-pro/charttip.svg')}" style="position: absolute;bottom: -16px;left: 50%;transform: translate(-50%, 0);" /></td></tr>`;
                            });
                            innerHtml += '</tbody>';

                            var tableRoot = tooltipEl.querySelector('table');
                            tableRoot.innerHTML = innerHtml;
                        }

                        // `this` will be the overall tooltip
                        var position = this._chart.canvas.getBoundingClientRect();

                        // Display, position, and set styles for font
                        tooltipEl.style.backgroundColor = '#241C78'
                        tooltipEl.style.borderRadius = '4px'
                        tooltipEl.style.opacity = 1;
                        tooltipEl.style.position = 'absolute';
                        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.offsetWidth / 2) + 'px';
                        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 8 + 'px';
                        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                        tooltipEl.style.pointerEvents = 'none';
                    }
                }
            },
        }
    },
    props: {
        records: {
            type: Array,
            default: () => []
        },
        isCurrency: {
            type: Boolean,
            default: true
        }
    },
    mounted () {
        var ctx = document.getElementById('line-chart').getContext("2d");
        this.gradientFill = ctx.createLinearGradient(0, 0, 0, 200);
        this.gradientFill.addColorStop(0, "rgba(36, 28, 120, 0.2)");
        this.gradientFill.addColorStop(1, "rgba(36, 28, 120, 0)");
        this.chartData.datasets[0].backgroundColor = this.gradientFill;
        this.renderChart(this.chartData, this.options)
    },
    watch: {
        records: function(){
            var ctx = document.getElementById('line-chart').getContext("2d");
            this.gradientFill = ctx.createLinearGradient(0, 0, 0, 200);
            this.gradientFill.addColorStop(0, "rgba(36, 28, 120, 0.2)");
            this.gradientFill.addColorStop(1, "rgba(36, 28, 120, 0)");
            this.chartData.datasets[0].backgroundColor = this.gradientFill;
            this.renderChart(this.chartData, this.options)
        }
    },
    computed: {
        chartData(){
            return ({
                labels: this.records.map(ele => moment(ele.label).format('DD MMM, YY')),
                datasets: [{
                    label: this.isCurrency ? '' : 'notCurrency',
                    data: this.records.map(ele => ele.data),
                    fill: 'start',
                    lineTension: 0.5,
                    borderColor: '#1A1739',
                    borderWidth: 2,
                    pointBackgroundColor: 'transparent',
                    pointBorderColor: 'transparent',
                    backgroundColor: '#1A1739',
                    pointHoverRadius: 12,
                    pointRadius: 12
                }]
            })
        }
    }
}
</script>
