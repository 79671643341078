<template>
    <div class="exchange-accounts-table">
        <div class="db-modal add-exchange-accounts">
            <div class="content">
                <div class="wrapper">
                    <h3>Add new account</h3>
                    <div class="new-user-form">
                        <InputUnit class="mobile-full" title="Portfolio" type="select" :selectOptions="portfolioOptions" v-model="newAccountForm.product"/>
                        <InputUnit title="Exchange" type="select" :selectOptions="exchangeOptions" v-model="newAccountForm.xchg"/>
                        <InputUnit title="Name" type="text" placeholder="Account name..." v-model="newAccountForm.account_name" :hasBtn="false" />
                        <InputUnit title="Account Mode" type="select" :selectOptions="acModeOptions" v-model="newAccountForm.account_mode"/>
                        <InputUnit class="mobile-full" title="API Key" type="text" placeholder="Account api key..." v-model="newAccountForm.key" :hasBtn="false" />
                        <InputUnit class="mobile-full" title="API Secret" type="text" placeholder="Account api secret..." v-model="newAccountForm.secret" :hasBtn="false" />
                        <InputUnit class="mobile-full" title="API Passphrase" type="text" placeholder="Account api passphrase..." v-model="newAccountForm.passphrase" :hasBtn="false" />
                    </div>
                </div>
                <div class="btn-container">
                    <button class="blue" @click="closeModal(0)">Cancel</button>
                    <button class="light" @click="handleCreate()">Create</button>
                </div>
            </div>
        </div>
        <div class="searchAndAdd">
            <InputUnit title="" placeholder="Search by name" v-model="keyword" type="text" />
            <button class="blue" @click="openModal()">+ Add</button>
        </div>
        <table>
            <tr class="header">
                <th>Account Name</th>
                <th>Exchange</th>
                <th>Portfolio</th>
                <th>Standard</th>
                <th v-if="false">Action</th>
            </tr>
            <tr :key="ind" v-for="(ele, ind) in filteredData" @click="navigate(ele.account_name)">
                <td><img class="icon" :src="loadExchangeIcon(ele.xchg)" />{{ ele.account_name }}</td>
                <td>{{ ele.xchg }}</td>
                <td>{{ ele.product }}</td>
                <td>{{ ele.standard && ele.standard.toUpperCase() }}</td>
                <td><a href="#" @click="navigate(ele.account_name)"><img src="@/assets/images/setting.svg" /></a><a href="#" @click.stop="deleteExchangeAccount(ele.account_name)"><img src="@/assets/images/dashboard-pro/delete.svg" /></a></td>
            </tr>
            <!--
            <tr @click="navigate()">
                <td><img class="icon" src="@/assets/images/binance.svg" />USDT01_onebitARB</td>
                <td>Binance</td>
                <td>Portfolio A</td>
                <td>BTC</td>
                <td v-if="false"><a href="#"><img src="@/assets/images/exchange.svg" /></a><a href="#"><img src="@/assets/images/setting.svg" /></a></td>
            </tr>-->
        </table>
        <div class="cards-for-mobile">
            <div :key="ind" v-for="(ele, ind) in filteredData" @click="navigate(ele.account_name)">
                <span>{{ ele.standard && ele.standard.toUpperCase() }}</span>
                <h6><img class="icon" :src="loadExchangeIcon(ele.xchg)" />{{ ele.xchg }}</h6>
                <h5>{{ ele.account_name }}</h5>
                <p>{{ ele.product }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import InputUnit from '@/components/InputUnit';
import {apiCreateAccount, apiDeleteAccount} from '@/apis/pro/accounts.js';
import {apiPortfoliosList} from '@/apis/pro/portfolios.js';
import {encrypt} from '@/utils/Encryption.js';
import $ from 'jquery';
export default {
    name: 'ExchangeAccountsTable',
    data(){
        return {
            newAccountForm:{
                product: '',
                xchg: 'binance',
                account_name: '',
                key: '',
                secret: '',
                passphrase: '',
                symbols: '',
                account_mode: 0
            },
            portfolioOptions: [],
            acModeOptions: [{
                title: 'Normal',
                value: '0'
            },{
                title: 'Omni',
                value: '1'
            }],
            exchangeOptions: [{
                title: 'Binance',
                value: 'binance'
            }, {
                title: 'Okex',
                value: 'okex'
            }, {
                title: 'Huobi',
                value: 'huobi'
            }, {
                title: 'Bybit',
                value: 'bybit'
            }, {
                title: 'Bitfinex',
                value: 'bitfinex'
            }, {
                title: 'Bitmex',
                value: 'bitmex'
            }, {
                title: 'Mexc',
                value: 'mexc'
            }, {
                title: 'Gateio',
                value: 'gateio'
            }, {
                title: 'Woo',
                value: 'woo'
            }, {
                title: 'Jojo',
                value: 'jojo'
            }, {
                title: 'Coinex',
                value: 'coinex'
            }],
            keyword: ''
        }
    },
    props: {
        data: {
            type: Array,
            default: () => []
        },
        defaultPortfolio: {
            type: String,
            default: ''
        }
    },
    methods: {
        openModal(){ // usd, usdt
            $('.db-modal.add-exchange-accounts').fadeIn(300).css('display', 'flex');
        },
        closeModal(mode=1){ //Cancel = 0, Save = 1
            if(mode){
                // save
                $('.db-modal.add-exchange-accounts').fadeOut(300);
            }else{
                $('.db-modal.add-exchange-accounts').fadeOut(300);
            }
        },
        navigate(id){
            window.location = `/dashboard-pro/exchange/${id}`
        },
        loadPortfoliosList(){
            apiPortfoliosList().then(res => {
                if(res.data.result){
                    this.portfolioOptions = res.data.products.map(ele => ({title: ele.product_name, value: ele.product_name}))
                    this.newAccountForm.product = this.portfolioOptions.length ? this.portfolioOptions[0].value : ''
                    if(this.defaultPortfolio != '' && this.portfolioOptions.find(ele => ele.value == this.defaultPortfolio))this.newAccountForm.product = this.defaultPortfolio
                }else{
                    this.$toast.error(res.data.err_msg, {
                        timeout: 2000
                    });
                }
            })
        },
        validation(){
            if(this.newAccountForm.product == ''){
                this.$toast.error("Please select a portfolio", {
                    timeout: 2000
                });
                return false;
            }else if(this.newAccountForm.xchg == ''){
                this.$toast.error("Please select an exchange", {
                    timeout: 2000
                });
                return false;
            }else if(this.newAccountForm.account_name == ''){
                this.$toast.error("Please enter an account name", {
                    timeout: 2000
                });
                return false;
            }else if(this.newAccountForm.key == ''){
                this.$toast.error("Please enter your account's API Key", {
                    timeout: 2000
                });
                return false;
            }else if(this.newAccountForm.secret == ''){
                this.$toast.error("Please enter your account's API Secret", {
                    timeout: 2000
                });
                return false;
            }else{
                return true;
            }
        },
        handleCreate(){
            if(this.validation()){
                let submitForm = Object.assign({}, this.newAccountForm);
                const origin = {
                    api_key: submitForm.key,
                    api_secret: submitForm.secret,
                    api_passphrase: submitForm.passphrase
                }
                const key = `${origin.api_key.slice(0, 5)}${origin.api_key.slice(-6, -1)}`
                const aes = encrypt(JSON.stringify(origin))
                const plength = parseInt(aes.length / 2)
                const secret = aes.slice(0, plength)
                const check_sum = aes.slice(plength, aes.length)
                submitForm.key = key
                submitForm.secret = secret
                submitForm.check_sum = check_sum
                submitForm.update_key = true
                delete submitForm.passphrase
                apiCreateAccount(submitForm).then(res => {
                    if(res.data.result){
                        this.$toast.success('Account Creadted!', {
                            timeout: 2000
                        });
                        location.reload()
                    }else{
                        this.$toast.error(res.data.err_msg, {
                            timeout: 2000
                        });
                    }
                })
            }
        },
        loadExchangeIcon(name = ""){
            try {
                return require(`@/assets/images/exchange-logos/${name.toLowerCase()}.svg`);
            } catch (e) {
                return require(`@/assets/images/crypto-default.png`);
            }
        },
        deleteExchangeAccount(aid){
            this.$confirm('Delete Exchange Account', `Are you sure you want to delete account '${aid}'?`).then(res => {
                if(res){
                    apiDeleteAccount({
                        account: aid,
                    }).then(res => {
                        if(res.data.result){
                            this.$toast.success('Account Deleted!', {
                                timeout: 2000
                            });
                            location.reload();
                        }else{
                            this.$toast.error(res.data.err_msg, {
                                timeout: 2000
                            });
                        }
                    })
                }
            })
        }
    },
    components: {
        InputUnit,
    },
    mounted() {
        $('body').on('click', '.db-modal > .content', function(e){
            e.stopPropagation();
        })
        this.loadPortfoliosList()
    },
    computed: {
        filteredData(){
            return this.data.filter(ele => ele.account_name.toLowerCase().indexOf(this.keyword.toLowerCase())>=0)
        }
    }
}
</script>